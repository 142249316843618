import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  examId : "",
  title: '',
  queue: [],
  answers: [],
  multipleChoice: [],
  result: [],
};

export const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    startExam: (state, action) => {
      const { questions , title, examId } = action.payload;
      state.queue = questions;
      state.title = title;
      state.examId = examId;
    },

    pushResult: (state, action) => {
      // Remove previous options for this question...
      state.result = state.result.filter(
        (item) => item.question !== action.payload.trace
      );

      // push the updated result of the question....
      state.result.push({
        question: action.payload.trace,
        answer: [action.payload.i],
      });
    },

    pushMultipleChoice: (state, action) => {
      // Resetting the multiple choice array on changing to a new question......
      const exists = state.result.find(
        (item) => item.question === action.payload.trace
      );

      if(exists){
        // console.log("Question Already Exists:", exists);
      }
      else {
        // console.log("Question doesnot exist:");
        state.multipleChoice = [];
      }

    
      state.multipleChoice.push(action.payload.i);
      // Remove the previous answers to this question and update to the new one ...
      state.result = state.result.filter(
        (item) => item.question !== action.payload.trace
      );
      // push the result to the redux store .....
      state.result.push({
        question: action.payload.trace,
        answer: state.multipleChoice,
      });
    },

    filterMultipleChoice: (state, action) => {
      state.multipleChoice = state.multipleChoice.filter(
        (item) => item !== action.payload.i
      );

      // Remove the previous answers to this question and update to the new one ...
      state.result = state.result.filter(
        (item) => item.question !== action.payload.trace
      );

      // pushing the updated answers for the questions .....
      state.result.push({
        question: action.payload.trace,
        answer: state.multipleChoice,
      });
    },

    resetAll: (state, action) => {
      return {
        examId : '',
        title: '',
        queue: [],
        answers: [],
        result: [],
        multipleChoice: [],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startExam,
  pushResult,
  resetAll,
  pushMultipleChoice,
  filterMultipleChoice,
} = examSlice.actions;

// selecting or retrieving the state .........
export const selectQuestions = (state) => state.exam.queue;
export const currentQuestion = (state) => state.exam.trace;
export const selectedAnswers = (state) => state.exam.result;
export const selectExamTitle = (state) => state.exam.title;
export const selectExamId = (state) => state.exam.examId;
export const selectMultipleChoice = (state) => state.exam.multipleChoice;

export default examSlice.reducer;
