import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import { persistor, store } from "./app/store";
import { Provider } from "react-redux";
import App from "./App";
import LoadingScreen from "./components/LoadingScreen";
import { QueryClient, QueryClientProvider } from "react-query";
import { PersistGate } from 'redux-persist/integration/react';


const queryClient = new QueryClient();

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import("./pages/Login")));
const SignUp = Loadable(lazy(() => import("./pages/SignUp")));
const ComingSoon = Loadable(lazy(() => import("./pages/ComingSoon")));
const EmailVerification = Loadable(
  lazy(() => import("./pages/EmailVerification"))
);
const PasswordReset = Loadable(lazy(() => import("./pages/PasswordReset")));
const ForgotPassword = Loadable(lazy(() => import("./pages/ForgotPassword")));
const SentEmail = Loadable(lazy(() => import("./pages/SentEmail")));
const NotApproved = Loadable(lazy(() => import("./pages/NotApproved")));
const About = Loadable(lazy(() => import("./components/sections/AboutPage")));
const Events = Loadable(lazy(() => import("./pages/Events")));
const Home = Loadable(lazy(() => import("./pages/Home")));
const ProgramCourses = Loadable(lazy(() => import("./pages/ProgramCourses")));
const Inspirations = Loadable(lazy(() => import("./pages/Inspirations")));
const Contact = Loadable(
  lazy(() => import("./components/sections/ContactPage"))
);
const CourseDetails = Loadable(lazy(() => import("./pages/CourseDetails")));
const ChangePassword = Loadable(lazy(() => import("./pages/ChangePassword")));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/coming" element={<ComingSoon />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/activate" element={<SentEmail />} />
            <Route path="/events" element={<Events />} />
            <Route path="/program/:programId" element={<ProgramCourses />} />
            <Route path="/course/:courseId" element={<CourseDetails />} />
            <Route path="/inspirations" element={<Inspirations />} />
            <Route path="/notapproved" element={<NotApproved />} />
            <Route path="/verify/:token" element={<EmailVerification />} />
            <Route path="/reset-password/:token" element={<PasswordReset />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>

    </PersistGate>

  </Provider>
);
