import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { IoAnalytics } from "react-icons/io5";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { ChatAlt2Icon, UserCircleIcon } from "@heroicons/react/solid";
import { IoHome } from "react-icons/io5";
import { CiClock1 } from "react-icons/ci";
import { GiGraduateCap } from "react-icons/gi";


export const StudentMenu = [
    {
        _id: 1,
        Icon: IoHome,
        title: 'Home',
        path: "/"

    },

    {
        _id: 2,
        Icon: UserCircleIcon,
        title: 'Account',
        path: "/profile"

    },

    {
        _id: 3,
        Icon: GiGraduateCap,
        title: 'Programs',
        path: "/programs"

    },
    {
        _id: 5,
        Icon: ChatAlt2Icon,
        title: 'Private chats',
        path: "/charts"

    },

    {
        _id: 6,
        Icon: HiOutlineQuestionMarkCircle,
        title: 'Enquiries',
        path: "/enquiries"

    },

]

export const TutorMenu = [
    {
        _id: 1,
        Icon: IoHome,
        title: 'Home',
        path: "/"

    },
    {
        _id: 2,
        Icon: UserCircleIcon,
        title: 'Account',
        path: "/profile"

    },

    {
        _id: 3,
        Icon: GiGraduateCap,
        title: 'Programs',
        path: "/programs"

    },

    {
        _id: 4,
        Icon: ChatAlt2Icon,
        title: 'Private chats',
        path: "/charts"

    },
    {
        _id: 6,
        Icon: HiOutlineQuestionMarkCircle,
        title: 'Enquiries',
        path: "/enquiries"

    },
    {
        _id: 7,
        Icon: CiClock1,
        title: 'Track Time',
        path: "/coming"

    },
]

export const AdminMenu = [
    {
        _id: 1,
        Icon: IoHome,
        title: 'Home',
        path: "/"

    },
    {
        _id: 2,
        Icon: IoAnalytics,
        title: 'Analytics',
        path: "/analytics"

    },
    {
        _id: 3,
        Icon: MdOutlineDashboardCustomize,
        title: 'Dashboard',
        path: "/dashboard"

    },

    {
        _id: 4,
        Icon: UserCircleIcon,
        title: 'Account',
        path: "/profile"

    },

    {
        _id: 5,
        Icon: GiGraduateCap,
        title: 'Programs',
        path: "/programs"

    },

    {
        _id: 6,
        Icon: ChatAlt2Icon,
        title: 'Private chats',
        path: "/charts"

    },
]

export const GuestMenu = [
    {
        _id: 1,
        Icon: IoHome,
        title: 'Home',
        path: "/"

    },

    {
        _id: 2,
        Icon: GiGraduateCap,
        title: 'Programs',
        path: "/programs"

    },
    {
        _id: 3,
        Icon: ChatAlt2Icon,
        title: 'Private chats',
        path: "/charts"

    },
    {
        _id: 4,
        Icon: HiOutlineQuestionMarkCircle,
        title: 'Enquiries',
        path: "/enquiries"

    },
]